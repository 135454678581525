<span class="search-container" [class.open]="isSearching$ | ngrxPush">
  <sm-search
    #searchComponent
    class="search-header"
    [class.regex-error]="regexError"
    [value]="(searchQuery$ | ngrxPush)?.original"
    [placeholder]="searchPlaceholder$ | ngrxPush"
    [hideIcons]="true"
    [minimumChars]="minChars"
    (focusout)="onSearchFocusOut()"
    (valueChanged)="search($event)"
  >
    @if (regexError) {
      <mat-icon class="error regexp" fontSet="al" fontIcon="al-ico-error-circle" inline [smTooltip]="regexError" [matTooltipPosition]="'below'"></mat-icon>
    }
    <button mat-icon-button
      class="regexp sm"
      [class.active]="regExp"
      smClickStopPropagation
      [smTooltip]="'Regex'" [matTooltipPosition]="'below'"
      (click)="toggleRegExp(); searchComponent.searchBarInput.nativeElement.focus();"
      >
        <mat-icon fontSet="al" fontIcon="al-ico-regex" data-id="enableRegexButton"></mat-icon>
      </button>
  </sm-search>
</span>
@if (searchActive()) {
  @if ((isSearching$ | ngrxPush)) {
    <button mat-icon-button (click)="clearSearch()" smClickStopPropagation>
      <mat-icon
        fontSet="al"
        fontIcon="al-ico-dialog-x"
        data-id="closeSearchButton">
      </mat-icon>
    </button>
  } @else {
    <button mat-icon-button (click)="openSearch()" data-id="searchIcon">
      <mat-icon fontSet="al" fontIcon="al-ico-search" data-id="search Icon"></mat-icon>
    </button>
  }
}
